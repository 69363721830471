@import "_tailwind";
@import "figma";

@import "_lato";

//
// Import a bit of Bootstrap so we stay on sync
// with the rest of the backoffice
//
@import "landing/bootstrap/variables";
@import "landing/variables";
@import "bootstrap/variables";
@import "_variables";
@import "../../node_modules/bootstrap/scss/variables";
@import "../../node_modules/bootstrap/scss/mixins";
@import "../../node_modules/bootstrap/scss/reboot";
@import "../../node_modules/bootstrap/scss/utilities";
@import "../../node_modules/bootstrap/scss/dropdown";
@import "../../node_modules/bootstrap/scss/buttons";
@import "../../node_modules/bootstrap/scss/button-group";
@import "../../node_modules/bootstrap/scss/alert";
@import "../../node_modules/bootstrap/scss/mixins/breakpoints";
@import "bootstrap/alpha_fixes";

//
// Mixins
//

// Bootstrap overrides
@import "landing/bootstrap/alert";
@import "landing/bootstrap/dropdown";
@import "landing/bootstrap/buttons";
@import "bootstrap/buttons";

$vertical-padding: 2rem;
$horizontal-padding: 5rem;

html {
  overflow: auto;
}

html,
body {
  @include media-breakpoint-up(md) {
    position: absolute;
    top: 0;
    right: 0;
    bottom: 0;
    left: 0;
  }
  margin: 0;
  padding: 0;
  background-color: #82a1f4;
}

body {
  padding: $vertical-padding $horizontal-padding;
  min-width: $body-min-width;
  min-height: 540px;
  background-image: url(../images/landing/noise.png), url(../images/landing/noise.png),
    radial-gradient(at top right, #7aa2fb 0%, #006cd1 100%);
}

@include media-breakpoint-down(sm) {
  body {
    padding: 0;
  }
}

.btn-primary {
  @include button-variant($btn-primary-bg, $btn-primary-border);
  color: $btn-primary-color;
}

//
// Landing
//
@import "landing/content";
@import "landing/alignments";
@import "landing/type";
@import "landing/form";
@import "landing/footer";
@import "landing/sso";

@import "modules/bouncing";
@import "modules/btn_borderless";
