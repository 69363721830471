@use "sass:math";
@import "../../../node_modules/bootstrap/scss/functions";

// Variables
//
// Copy settings from this file into the provided `_custom.scss` to override
// the Bootstrap defaults without modifying key, versioned files.
//
// Variables should follow the `$component-state-property-size` formula for
// consistent naming. Ex: $nav-link-disabled-color and $modal-content-box-shadow-xs.

// Table of Contents
//
// Color system
// Options
// Spacing
// Body
// Links
// Paragraphs
// Grid breakpoints
// Grid containers
// Grid columns
// Fonts
// Components
// Tables
// Buttons
// Forms
// Dropdowns
// Z-index master list
// Navs
// Navbar
// Pagination
// Jumbotron
// Form states and alerts
// Cards
// Tooltips
// Popovers
// Badges
// Modals
// Alerts
// Progress bars
// List group
// Image thumbnails
// Figures
// Breadcrumbs
// Carousel
// Close
// Code

//
// Color system
//

// Ludo : I matched Bootstrap grays to our chambrays for compatbility with BS tools only.
// Please don't use $gray- variables in backoffice files
$gray-100: $neutral-25 !default;
$gray-200: $neutral-50 !default;
$gray-300: $neutral-100 !default;
$gray-400: $neutral-200 !default;
$gray-500: $neutral-300 !default;
$gray-600: $neutral-400 !default;
$gray-700: $neutral-600 !default;
$gray-800: $neutral-700 !default;
$gray-900: $neutral-900 !default;
$black: #000 !default;

$grays: (
  100: $gray-100,
  200: $gray-200,
  300: $gray-300,
  400: $gray-400,
  500: $gray-500,
  600: $gray-600,
  700: $gray-700,
  800: $gray-800,
  900: $gray-900,
) !default;

$primary: $primary-500 !default;
$indigo: #6610f2 !default;
$purple: $purple-500 !default;
$pink: #e83e8c !default;
$danger: $danger-500 !default;
$orange: $orange-500 !default;
$yellow: $yellow-500 !default;
$success: $success-500 !default;
$teal: #20c997 !default;
$cyan: $turquoise-600 !default;

//$colors: (
//  blue: $primary,
//  indigo: $indigo,
//  purple: $purple,
//  pink: $pink,
//  red: $danger,
//  orange: $orange,
//  yellow: $yellow,
//  green: $success,
//  teal: $teal,
//  cyan: $cyan,
//  white: $white,
//  gray: $gray-600,
//  gray-dark: $gray-800
//) !default;
//
$theme-colors: (
  primary: $primary,
  secondary: $neutral-500,
  success: $success,
  info: $primary-200,
  warning: $yellow,
  danger: $danger-400,
  light: $gray-100,
  dark: $gray-800,
) !default;

// Set a specific jump point for requesting color jumps
//$theme-color-interval: 8% !default;

// Options
//
// Quickly modify global styling by enabling or disabling optional features.

//$enable-rounded:            true !default;
//$enable-shadows:            false !default;
//$enable-gradients:          false !default;
//$enable-transitions:        true !default;
//$enable-hover-media-query:  false !default;
//$enable-grid-classes:       true !default;
//$enable-print-styles:       true !default;

// Spacing
//
// Control the default styling of most Bootstrap elements by modifying these
// variables. Mostly focused on spacing.
// You can add more entries to the $spacers map, should you need more variation.

$spacer: 1rem !default;
$spacers: (
  0: 0,
  1: (
    $spacer * 0.25,
  ),
  2: (
    $spacer * 0.5,
  ),
  3: $spacer,
  4: (
    $spacer * 1.5,
  ),
  5: (
    $spacer * 3,
  ),
) !default;

// This variable affects the `.h-*` and `.w-*` classes.
$sizes: (
  25: 25%,
  50: 50%,
  75: 75%,
  100: 100%,
) !default;

// Body
//
// Settings for the `<body>` element.

$body-bg: $bg-box !default;
$body-color: $text-body !default;

// Links
//
// Style anchor elements.

$link-color: $primary-500 !default;
$link-decoration: none !default;
$link-hover-color: $primary-600 !default;
$link-hover-decoration: underline !default;

// Paragraphs
//
// Style p element.

$paragraph-margin-bottom: 1rem;

// Grid breakpoints
//
// Define the minimum dimensions at which your layout will change,
// adapting to different screen sizes, for use in media queries.

$grid-breakpoints: (
  xs: 0,
  sm: 576px,
  md: 800px,
  lg: 1000px,
  xl: 1200px,
  xxl: 1400px,
) !default;
//@include _assert-ascending($grid-breakpoints, "$grid-breakpoints");
//@include _assert-starts-at-zero($grid-breakpoints);

// Grid containers
//
// Define the maximum width of `.container` for different screen sizes.

$container-max-widths: (
  xs: 1200px,
) !default;
//@include _assert-ascending($container-max-widths, "$container-max-widths");

// Grid columns
//
// Set the number of columns and specify the width of the gutters.

$grid-columns: 16 !default;
$grid-gutter-width: 1.5rem !default;

// Components
//
// Define common padding and border radius sizes and more.

$line-height-lg: math.div(20, 14) !default;
$line-height-sm: math.div(20, 14) !default;

//$border-width: 1px !default;
$border-color: $border-box !default;

$border-radius: 3px !default;
$border-radius-lg: 4px !default;
$border-radius-xlg: 8px !default;
$border-radius-sm: 2px !default;

$component-active-color: $primary-500 !default;
$component-active-bg: $neutral-50 !default;

// Non standard
$box-shadow: $shadow-box;

//$caret-width:            .3em !default;

//$transition-base:        all .2s ease-in-out !default;
//$transition-fade:        opacity .15s linear !default;
//$transition-collapse:    height .35s ease !default;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$font-family-sans-serif: "Lato", "Helvetica Neue", Arial, sans-serif !default;
//$font-family-monospace:  "SFMono-Regular", Menlo, Monaco, Consolas, "Liberation Mono", "Courier New", monospace !default;
//$font-family-base:       $font-family-sans-serif !default;

$font-size-base: 0.875rem !default; // 14px

$font-size-6xlg: 4.5rem !default; // 72px
$font-size-5xlg: 3.75rem !default; // 60px
$font-size-4xlg: 3rem !default; // 48px
$font-size-3xlg: 2.25rem !default; // 36px
$font-size-2xlg: 1.5rem !default; // 24px
$font-size-xlg: 1.3125rem !default; // 21px
$font-size-lg: 1rem !default; // 16px
$font-size-md: $font-size-base !default; // 14px
$font-size-sm: 0.75rem !default; // 12px
$font-size-xs: 0.625rem !default; // 10px

$font-weight-light: 300 !default;
$font-weight-normal: 400 !default;
$font-weight-bold: 600 !default;
$font-weight-bolder: 800 !default;

//$font-weight-base: $font-weight-normal !default;
$line-height-base: math.div(20, 14) !default;

$h1-font-size: $font-size-3xlg !default;
$h2-font-size: $font-size-xlg !default;
//$h3-font-size: 1.75rem !default;
//$h4-font-size: 1.5rem !default;
$h5-font-size: $font-size-lg !default;
$h6-font-size: $font-size-base !default;

//$headings-margin-bottom: (math.div($spacer, 2)) !default;
//$headings-font-family:   inherit !default;
//$headings-font-weight:   500 !default;
$headings-line-height: $line-height-base;
$headings-color: $body-color !default; // TODO: check if chambray-800 wasnt better

////$display1-size: 6rem !default;
////$display2-size: 5.5rem !default;
////$display3-size: 4.5rem !default;
////$display4-size: 3.5rem !default;
////
////$display1-weight:     300 !default;
////$display2-weight:     300 !default;
////$display3-weight:     300 !default;
////$display4-weight:     300 !default;
////$display-line-height: $headings-line-height !default;
//
$lead-font-size: $font-size-lg !default;
$lead-font-weight: 600 !default;
//
//$small-font-size: 80% !default;

// $inverted-text-muted: $gray-500;

//$blockquote-small-color:  $gray-600 !default;
//$blockquote-font-size:    ($font-size-base * 1.25) !default;
$hr-border-color: $border-box !default;
//$hr-border-width: $border-width !default;
//
//$mark-padding: .2em !default;
//
//$dt-font-weight: $font-weight-bold !default;
//
//$kbd-box-shadow:         inset 0 -.1rem 0 rgba($black,.25) !default;
//$nested-kbd-font-weight: $font-weight-bold !default;
//
//$list-inline-padding: 5px !default;
//
//$mark-bg: #fcf8e3 !default;

// Tables
//
// Customizes the `.table` component with basic values, each used across all table variations.

$table-cell-padding: 0.5rem !default;
$table-cell-padding-sm: 0.5rem !default;
//
//$table-bg:                      transparent !default;
//$table-accent-bg:               rgba($black,.05) !default;
$table-hover-bg: $neutral-25;
$table-active-bg: $primary-25 !default;
//
//$table-border-width:            $border-width !default;
$table-border-color: $border-inside-box;

$table-head-bg: $neutral-50;
$table-head-color: $text-muted;
$table-head-hover-bg: $bg-strong-hover;
//
//$table-inverse-bg:              $gray-900 !default;
//$table-inverse-accent-bg:       rgba($white, .05) !default;
//$table-inverse-hover-bg:        rgba($white, .075) !default;
//$table-inverse-border-color:    lighten($gray-900, 7.5%) !default;
//$table-inverse-color:           $body-bg !default;

// Buttons
//
// For each of Bootstrap's buttons, define text, background and border color.

$input-btn-padding-y: 0.5rem !default;
$input-btn-padding-x: 0.5rem !default;
$input-btn-line-height: math.div(
  16,
  14
) !default; // So a font-size(14px) button will have a line-height of 16px.

$input-btn-padding-y-sm: 0.25rem !default;
$input-btn-padding-x-sm: 0.25rem !default;
$input-btn-line-height-sm: math.div(14, 12) !default;

$input-btn-padding-y-lg: 0.5rem !default;
$input-btn-padding-x-lg: 0.5rem !default;
$input-btn-line-height-lg: math.div(16, 14) !default;

//$btn-font-weight:              $font-weight-normal !default;
$btn-box-shadow: 0 2px 0px rgba($neutral-800, 0.05);
$btn-focus-box-shadow: none !default;
$btn-active-box-shadow: 0 1px 0px rgba($neutral-800, 0.1);

//$btn-link-disabled-color:        $gray-600 !default;

//$btn-block-spacing-y:            .5rem !default;

// Allows for customizing button radius independently from global border radius
//$btn-border-radius:              $border-radius !default;
//$btn-border-radius-lg:           $border-radius-lg !default;
//$btn-border-radius-sm:           $border-radius-sm !default;

//$btn-transition:                 background-color .15s ease-in-out, border-color .15s ease-in-out, box-shadow .15s ease-in-out !default;

// Previous Variables
// Those one have been deleted in beta so they should be moved somewhere else
$btn-primary-color: $white !default;
$btn-primary-bg: $primary-500 !default;
$btn-primary-border: $primary-600 !default;

$btn-secondary-color: $text-body !default;
$btn-secondary-bg: $white !default;
$btn-secondary-border: $neutral-100 !default;

$btn-success-color: $white !default;
$btn-success-bg: $success-500 !default;
$btn-success-border: $success-600 !default;

// Forms

//$input-bg:                       $white !default;
//$input-disabled-bg:              $gray-200 !default;

$input-color: $text-body !default;
$input-border-color: $neutral-200 !default;
//$input-btn-border-width:         $border-width !default; // For form controls and buttons
//$input-box-shadow:               inset 0 1px 1px rgba($black,.075) !default;

//$input-border-radius:            $border-radius !default;
//$input-border-radius-lg:         $border-radius-lg !default;
//$input-border-radius-sm:         $border-radius-sm !default;

//$input-focus-bg:                 $input-bg !default;
$input-focus-border-color: $primary-400 !default;
//$input-focus-box-shadow:         $input-box-shadow, $btn-focus-box-shadow !default;
//$input-focus-color:              $input-color !default;

$input-placeholder-color: $text-placeholder !default; // TODO : See if its still good

//$input-height-border:           $input-btn-border-width * 2 !default;

//$input-height-inner:            ($font-size-base * $input-btn-line-height) + ($input-btn-padding-y * 2) !default;
//$input-height:                  calc(#{$input-height-inner} + #{$input-height-border}) !default;
//
//$input-height-inner-sm:         ($font-size-sm * $input-btn-line-height-sm) + ($input-btn-padding-y-sm * 2) !default;
//$input-height-sm:               calc(#{$input-height-inner-sm} + #{$input-height-border}) !default;
//
//$input-height-inner-lg:         ($font-size-lg * $input-btn-line-height-lg) + ($input-btn-padding-y-lg * 2) !default;
//$input-height-lg:               calc(#{$input-height-inner-lg} + #{$input-height-border}) !default;

//$input-transition:               border-color ease-in-out .15s, box-shadow ease-in-out .15s !default;

//$form-text-margin-top:     .25rem !default;

//$form-check-margin-bottom:  .5rem !default;
//$form-check-input-gutter:   1.25rem !default;
//$form-check-input-margin-y: .25rem !default;
//$form-check-input-margin-x: .25rem !default;

//$form-check-inline-margin-x: .75rem !default;

//$form-group-margin-bottom:       1rem !default;

//$input-group-addon-color:        $input-color !default;
//$input-group-addon-bg:           $gray-200 !default;
//$input-group-addon-border-color: $input-border-color !default;
//$input-group-btn-border-color:   $input-border-color !default;

$custom-control-gutter: 1rem !default;
//$custom-control-spacer-y: .25rem !default;
//$custom-control-spacer-x: 1rem !default;

//$custom-control-indicator-size:       1rem !default;
$custom-control-indicator-bg: transparent;
//$custom-control-indicator-bg-size:    50% 50% !default;
//$custom-control-indicator-box-shadow: inset 0 .25rem .25rem rgba($black,.1) !default;

//$custom-control-indicator-disabled-bg:       $gray-200 !default;
//$custom-control-description-disabled-color:  $gray-600 !default;

//$custom-control-indicator-checked-color:      $white !default;
$custom-control-indicator-checked-bg: transparent;
//$custom-control-indicator-checked-box-shadow: none !default;

$custom-control-indicator-focus-box-shadow: 0 0 0 0.075rem $primary-200 !default;

//$custom-control-indicator-active-color:      $white !default;
$custom-control-indicator-active-bg: transparent;
//$custom-control-indicator-active-box-shadow: none !default;

//$custom-checkbox-indicator-border-radius: $border-radius !default;
//$custom-checkbox-indicator-icon-checked: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 8 8'%3E%3Cpath fill='#{$custom-control-indicator-checked-color}' d='M6.564.75l-3.59 3.612-1.538-1.55L0 4.26 2.974 7.25 8 2.193z'/%3E%3C/svg%3E"), "#", "%23") !default;

//$custom-checkbox-indicator-indeterminate-bg: theme-color("primary") !default;
//$custom-checkbox-indicator-indeterminate-color: $custom-control-indicator-checked-color !default;
//$custom-checkbox-indicator-icon-indeterminate: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 4 4'%3E%3Cpath stroke='#{$custom-checkbox-indicator-indeterminate-color}' d='M0 2h4'/%3E%3C/svg%3E"), "#", "%23") !default;
//$custom-checkbox-indicator-indeterminate-box-shadow: none !default;

//$custom-radio-indicator-border-radius: 50% !default;
$custom-radio-indicator-icon-checked: none !default;

$custom-select-padding-y: $input-btn-padding-y !default;
$custom-select-padding-x: $input-btn-padding-x !default;
//$custom-select-height:              $input-height  !default;
$custom-select-indicator-padding: 1rem !default; // Extra padding to account for the presence of the background-image based indicator
//$custom-select-line-height:         $input-btn-line-height !default;
//$custom-select-color:               $input-color !default;
//$custom-select-disabled-color:      $gray-600 !default;
$custom-select-bg: $white;
//$custom-select-disabled-bg:   $gray-200 !default;
$custom-select-bg-size: 1rem 1rem !default; // In pixels because image dimensions
//$custom-select-indicator-color: #333 !default;
$custom-select-indicator: url("../images/color-chevron-small-down-filter.svg");
//$custom-select-border-width:  $input-btn-border-width !default;
$custom-select-border-color: $border-box;
//$custom-select-border-radius: $border-radius !default;

$custom-select-focus-border-color: $border-box;
//$custom-select-focus-box-shadow:   inset 0 1px 2px rgba($black, .075), 0 0 5px rgba($custom-select-focus-border-color, .5) !default;

$custom-select-font-size-sm: $font-size-sm;
//$custom-select-height-sm:     $input-height-sm !default;
//
//$custom-file-height:           $input-height !default;
//$custom-file-width:            14rem !default;
//$custom-file-focus-box-shadow: 0 0 0 .075rem $white, 0 0 0 .2rem theme-color("primary") !default;
//
//$custom-file-padding-y:     $input-btn-padding-y !default;
//$custom-file-padding-x:     $input-btn-padding-x !default;
//$custom-file-line-height:   $input-btn-line-height !default;
//$custom-file-color:         $input-color !default;
//$custom-file-bg:            $input-bg !default;
//$custom-file-border-width:  $input-btn-border-width !default;
//$custom-file-border-color:  $input-border-color !default;
//$custom-file-border-radius: $input-border-radius !default;
//$custom-file-box-shadow:    $input-box-shadow !default;
//$custom-file-button-color:  $custom-file-color !default;
//$custom-file-button-bg:     $input-group-addon-bg !default;
//$custom-file-text: (
//  placeholder: (
//    en: "Choose file..."
//  ),
//  button-label: (
//    en: "Browse"
//  )
//) !default;

// Form validation
//$form-feedback-valid-color:   theme-color("success") !default;
//$form-feedback-invalid-color: theme-color("danger") !default;

// Dropdowns
//
// Dropdown menu container and contents.

$dropdown-min-width: 80px !default;
$dropdown-padding-y: math.div($spacer, 4) !default;
$dropdown-padding-x: 0px !default; // Non standard
$dropdown-margin-top: 4px;
//$dropdown-spacer:                .125rem !default;
//$dropdown-bg:                    $white !default;
$dropdown-border-color: transparent !default;
$dropdown-border-width: 0px !default;
$dropdown-divider-bg: $gray-300 !default;
$dropdown-box-shadow: $shadow-dropdown;

//$dropdown-link-color:            $gray-900 !default;
$dropdown-link-hover-color: white !default;
$dropdown-link-hover-bg: $link-color !default;

//$dropdown-link-active-color:     $component-active-color !default;
$dropdown-link-active-bg: transparent !default;

//$dropdown-link-disabled-color:   $gray-600 !default;

$dropdown-item-padding-y: 0.5em !default;
$dropdown-item-padding-x: 1.25em !default; // widened to avoid horizontal scroll on OSX when scrollbars are always visible

$dropdown-header-color: $text-muted !default;

// Z-index master list
//
// Warning: Avoid customizing these values. They're used for a bird's eye view
// of components dependent on the z-axis and are designed to all work together.

//$zindex-dropdown:           1000 !default;
//$zindex-sticky:             1020 !default;
//$zindex-fixed:              1030 !default;
//$zindex-modal-backdrop:     1040 !default;
//$zindex-modal:              1050 !default;
//$zindex-popover:            1060 !default;
//$zindex-tooltip:            1070 !default;

// Navs

$nav-link-padding-y: 0.75rem !default;
$nav-link-padding-x: 1rem !default;
$nav-link-padding-y-sm: 0.5rem !default;
$nav-link-padding-x-sm: 0.75rem !default;
$nav-link-disabled-color: $gray-500 !default;

$nav-pills-link-padding-y: 0.5rem !default;
$nav-pills-link-padding-x: 1rem !default;
$nav-pills-link-padding-y-sm: 0.25rem !default;
$nav-pills-link-padding-x-sm: 0.5rem !default;

$nav-tabs-border-color: $neutral-100 !default;
$nav-tabs-border-width: 2px !default;
//$nav-tabs-border-radius:                      $border-radius !default;
$nav-tabs-link-hover-border-color: transparent !default;
$nav-tabs-link-active-color: theme-color("primary") !default;
$nav-tabs-link-active-bg: transparent !default;
//$nav-tabs-link-active-border-color:           #ddd !default;

//$nav-pills-border-radius:     $border-radius !default;
//$nav-pills-link-active-color: $component-active-color !default;
$nav-pills-link-active-bg: $primary-50 !default;

// Navbar

$navbar-padding-y: 0 !default;
$navbar-padding-x: math.div($spacer, 2) !default;

//$navbar-brand-font-size:            $font-size-lg !default;
// Compute the navbar-brand padding-y so the navbar-brand will have the same height as navbar-text and nav-link
//$nav-link-height:                   $navbar-brand-font-size * $line-height-base !default;
//$navbar-brand-height:               ($font-size-base * $line-height-base + $nav-link-padding-y * 2) !default;
//$navbar-brand-padding-y:            math.div(($navbar-brand-height - $nav-link-height), 2) !default;
//
//$navbar-toggler-padding-y:           .25rem !default;
//$navbar-toggler-padding-x:           .75rem !default;
//$navbar-toggler-font-size:           $font-size-lg !default;
//$navbar-toggler-border-radius:       $btn-border-radius !default;
//
//$navbar-dark-color:                 rgba($white,.5) !default;
//$navbar-dark-hover-color:           rgba($white,.75) !default;
//$navbar-dark-active-color:          rgba($white,1) !default;
//$navbar-dark-disabled-color:        rgba($white,.25) !default;
//$navbar-dark-toggler-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-dark-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23") !default;
//$navbar-dark-toggler-border-color:  rgba($white,.1) !default;
//
//$navbar-light-color:                rgba($black,.5) !default;
//$navbar-light-hover-color:          rgba($black,.7) !default;
//$navbar-light-active-color:         rgba($black,.9) !default;
//$navbar-light-disabled-color:       rgba($black,.3) !default;
//$navbar-light-toggler-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg viewBox='0 0 30 30' xmlns='http://www.w3.org/2000/svg'%3E%3Cpath stroke='#{$navbar-light-color}' stroke-width='2' stroke-linecap='round' stroke-miterlimit='10' d='M4 7h22M4 15h22M4 23h22'/%3E%3C/svg%3E"), "#", "%23") !default;
//$navbar-light-toggler-border-color: rgba($black,.1) !default;

// Pagination

//$pagination-padding-y:                .5rem !default;
//$pagination-padding-x:                .75rem !default;
//$pagination-padding-y-sm:             .25rem !default;
//$pagination-padding-x-sm:             .5rem !default;
//$pagination-padding-y-lg:             .75rem !default;
//$pagination-padding-x-lg:             1.5rem !default;
//$pagination-line-height:              1.25 !default;
//
//$pagination-color:                     $link-color !default;
//$pagination-bg:                        $white !default;
//$pagination-border-width:              $border-width !default;
//$pagination-border-color:              #ddd !default;
//
//$pagination-hover-color:               $link-hover-color !default;
//$pagination-hover-bg:                  $gray-200 !default;
//$pagination-hover-border-color:        #ddd !default;
//
//$pagination-active-color:              $white !default;
//$pagination-active-bg:                 theme-color("primary") !default;
//$pagination-active-border-color:       theme-color("primary") !default;
//
//$pagination-disabled-color:            $gray-600 !default;
//$pagination-disabled-bg:               $white !default;
//$pagination-disabled-border-color:     #ddd !default;

// Jumbotron

//$jumbotron-padding:              2rem !default;
//$jumbotron-bg:                   $gray-200 !default;

// Cards
//
//$card-spacer-y:            .75rem !default;
//$card-spacer-x:            1.25rem !default;
//$card-border-width:        1px !default;
//$card-border-radius:       $border-radius !default;
//$card-border-color:        rgba($black,.125) !default;
//$card-inner-border-radius: calc(#{$card-border-radius} - #{$card-border-width}) !default;
//$card-cap-bg:              rgba($black, .03) !default;
//$card-bg:                  $white !default;
//
//$card-img-overlay-padding: 1.25rem !default;
//
//$card-deck-margin:          (math.div($grid-gutter-width, 2)) !default;
//
//$card-columns-count:        3 !default;
//$card-columns-gap:          1.25rem !default;
//$card-columns-margin:       $card-spacer-y !default;

// Tooltips

$tooltip-max-width: 400px;
$tooltip-color: $text-tooltip-body !default;
$tooltip-bg: $bg-tooltip !default;
$tooltip-opacity: 0.95 !default;
$tooltip-padding-y: 0.25rem !default;
$tooltip-padding-x: 0.75rem !default;
//$tooltip-margin:              0 !default;

//$tooltip-arrow-width:          10px !default;
//$tooltip-arrow-height:         5px !default;
//$tooltip-arrow-color:         $toolstip-bg !default;

// Popovers

//$popover-inner-padding:               1px !default;
//$popover-bg:                          $white !default;
$popover-max-width: 400px !default;
//$popover-border-width:                $border-width !default;
$popover-border-color: $border-box;
$popover-box-shadow: $shadow-dropdown;
//
$popover-header-bg: $bg-box;
//$popover-header-color:                 $headings-color !default;
//$popover-header-padding-y:             8px !default;
//$popover-header-padding-x:             14px !default;

//$popover-body-color:               $body-color !default;
//$popover-body-padding-y:           9px !default;
//$popover-body-padding-x:           14px !default;
//
//$popover-arrow-width:                 10px !default;
//$popover-arrow-height:                5px !default;
//$popover-arrow-color:                 $popover-bg !default;
//
//$popover-arrow-outer-width:           ($popover-arrow-width + 1px) !default;
//$popover-arrow-outer-color:           fade-in($popover-border-color, .05) !default;

// Badges

$badge-font-size: 0.75rem !default; // TODO : reconsider rem
//$badge-font-weight:           $font-weight-bold !default;
$badge-padding-y: 0.25rem !default;
$badge-padding-x: 0.75rem !default;

$badge-pill-padding-x: 0.75rem !default;
// Use a higher than normal value to ensure completely rounded edges when
// customizing padding or font-size on labels.
//$badge-pill-border-radius:    10rem !default;

// Modals

// Padding applied to the modal body
$modal-inner-padding: 1rem !default;

//$modal-dialog-margin:         10px !default;
//$modal-dialog-margin-y-sm-up: 30px !default;

//$modal-title-line-height:     $line-height-base !default;

$modal-content-bg: $bg-box !default;
//$modal-content-border-color:     rgba($black,.2) !default;
//$modal-content-border-width:     $border-width !default;
//$modal-content-box-shadow-xs:    0 3px 9px rgba($black,.5) !default;
//$modal-content-box-shadow-sm-up: 0 5px 15px rgba($black,.5) !default;

$modal-backdrop-bg: $neutral-700 !default;
$modal-backdrop-opacity: 0.8 !default;
//$modal-header-border-color:   $gray-200 !default;
//$modal-footer-border-color:   $modal-header-border-color !default;
$modal-header-border-width: 0 !default;
$modal-footer-border-width: 0 !default;
//$modal-header-padding:        15px !default;

$modal-lg: 700px !default;
//$modal-md:                    500px !default;
//$modal-sm:                    300px !default;

//$modal-transition:            transform .3s ease-out !default;

// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-y: 0.75rem !default;
$alert-padding-x: 1rem !default;
//$alert-margin-bottom:         1rem !default;
//$alert-border-radius:         $border-radius !default;
//$alert-link-font-weight:      $font-weight-bold !default;
//$alert-border-width:          $border-width !default;

// Progress bars

//$progress-height:               1rem !default;
//$progress-font-size:            .75rem !default;
//$progress-bg:                   $gray-200 !default;
//$progress-border-radius:        $border-radius !default;
//$progress-box-shadow:           inset 0 .1rem .1rem rgba($black,.1) !default;
//$progress-bar-color:            $white !default;
//$progress-bar-bg:               theme-color("primary") !default;
//$progress-bar-animation-timing: 1s linear infinite !default;
//$progress-bar-transition:       width .6s ease !default;

// List group

//$list-group-bg:                  $white !default;
//$list-group-border-color:        rgba($black,.125) !default;
//$list-group-border-width:        $border-width !default;
//$list-group-border-radius:       $border-radius !default;
//
//$list-group-item-padding-y:      .75rem !default;
//$list-group-item-padding-x:      1.25rem !default;
//
//$list-group-hover-bg:                 $gray-100 !default;
//$list-group-active-color:             $component-active-color !default;
//$list-group-active-bg:                $component-active-bg !default;
//$list-group-active-border-color:      $list-group-active-bg !default;
//
//$list-group-disabled-color:      $gray-600 !default;
//$list-group-disabled-bg:         $list-group-bg !default;
//
//$list-group-action-color:             $gray-700 !default;
//$list-group-action-hover-color:       $list-group-action-color !default;
//
//$list-group-action-active-color:      $body-color !default;
//$list-group-action-active-bg:         $gray-200 !default;

// Image thumbnails

//$thumbnail-padding:           .25rem !default;
//$thumbnail-bg:                $body-bg !default;
//$thumbnail-border-width:      $border-width !default;
//$thumbnail-border-color:      #ddd !default;
//$thumbnail-border-radius:     $border-radius !default;
//$thumbnail-box-shadow:        0 1px 2px rgba($black,.075) !default;
//$thumbnail-transition:        all .2s ease-in-out !default;

// Figures

//$figure-caption-font-size: 90% !default;
//$figure-caption-color:     $gray-600 !default;

// Breadcrumbs

//$breadcrumb-padding-y:          .75rem !default;
//$breadcrumb-padding-x:          1rem !default;
//$breadcrumb-item-padding:       .5rem !default;
//
//$breadcrumb-margin-bottom:      1rem !default;
//
//$breadcrumb-bg:                 $gray-200 !default;
//$breadcrumb-divider-color:      $gray-600 !default;
//$breadcrumb-active-color:       $gray-600 !default;
//$breadcrumb-divider:            "/" !default;

// Carousel

//$carousel-control-color:                      $white !default;
//$carousel-control-width:                      15% !default;
//$carousel-control-opacity:                    .5 !default;
//
//$carousel-indicator-width:                    30px !default;
//$carousel-indicator-height:                   3px !default;
//$carousel-indicator-spacer:                   3px !default;
//$carousel-indicator-active-bg:                $white !default;
//
//$carousel-caption-width:                      70% !default;
//$carousel-caption-color:                      $white !default;
//
//$carousel-control-icon-width:                 20px !default;
//
//$carousel-control-prev-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3E%3Cpath d='M4 0l-4 4 4 4 1.5-1.5-2.5-2.5 2.5-2.5-1.5-1.5z'/%3E%3C/svg%3E"), "#", "%23") !default;
//$carousel-control-next-icon-bg: str-replace(url("data:image/svg+xml;charset=utf8,%3Csvg xmlns='http://www.w3.org/2000/svg' fill='#{$carousel-control-color}' viewBox='0 0 8 8'%3E%3Cpath d='M1.5 0l-1.5 1.5 2.5 2.5-2.5 2.5 1.5 1.5 4-4-4-4z'/%3E%3C/svg%3E"), "#", "%23") !default;
//
//$carousel-transition:           transform .6s ease !default;

// Close

//$close-font-size:             $font-size-base * 1.5 !default;
//$close-font-weight:           $font-weight-bold !default;
//$close-color:                 $black !default;
//$close-text-shadow:           0 1px 0 $white !default;
//
//// Code
//
//$code-font-size:              90% !default;
//$code-padding-y:              .2rem !default;
//$code-padding-x:              .4rem !default;
//$code-color:                  #bd4147 !default;
//$code-bg:                     $gray-100 !default;
//
//$kbd-color:                   $white !default;
//$kbd-bg:                      $gray-900 !default;
//
//$pre-color:                   $gray-900 !default;
//$pre-scrollable-max-height:   340px !default;

// Printing
$print-page-size: A4 !default;
$print-body-min-width: 0 !default;
