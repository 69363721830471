// This file contains ruled which fix bugs introduced in bootstrap during alpha and beta releases

.navbar .dropdown-menu.dropdown-menu-right {
  right: 0;
  left: auto;
}

// Button sizes were not using the input-btn-line-height-lg /sm var

.btn-lg {
  @include button-size(
    $input-btn-padding-y-lg,
    $input-btn-padding-x-lg,
    $font-size-lg,
    $input-btn-line-height-lg,
    $btn-border-radius-lg
  );
}

.btn-sm {
  @include button-size(
    $input-btn-padding-y-sm,
    $input-btn-padding-x-sm,
    $font-size-sm,
    $input-btn-line-height-sm,
    $btn-border-radius-sm
  );
}
