$bg-body: rgb(237, 242, 247, 1);
$bg-box: rgb(255, 255, 255, 1);
$bg-modal-backdrop: rgb(46, 62, 79, 0.8);
$bg-strong: rgb(225, 234, 242, 1);
$bg-strong-hover: rgb(213, 224, 235, 1);
$bg-tooltip: rgb(46, 62, 79, 1);
$black: rgb(0, 0, 0, 1);
$border-box: rgb(204, 218, 232, 1);
$border-inside-box: rgb(225, 234, 242, 1);
$danger-25: rgb(255, 240, 240, 1);
$danger-50: rgb(255, 230, 230, 1);
$danger-100: rgb(255, 207, 207, 1);
$danger-200: rgb(255, 174, 174, 1);
$danger-300: rgb(254, 134, 134, 1);
$danger-400: rgb(253, 93, 93, 1);
$danger-500: rgb(253, 50, 50, 1);
$danger-600: rgb(227, 4, 4, 1);
$danger-700: rgb(179, 1, 1, 1);
$danger-800: rgb(122, 1, 1, 1);
$danger-900: rgb(89, 0, 0, 1);
$extra-acid-green-100: rgb(240, 247, 225, 1);
$extra-acid-green-200: rgb(225, 238, 194, 1);
$extra-acid-green-300: rgb(211, 230, 164, 1);
$extra-acid-green-400: rgb(196, 221, 133, 1);
$extra-acid-green-500: rgb(181, 213, 103, 1);
$extra-acid-green-600: rgb(165, 201, 82, 1);
$extra-acid-green-700: rgb(135, 174, 52, 1);
$extra-acid-green-800: rgb(105, 138, 38, 1);
$extra-acid-green-900: rgb(80, 106, 33, 1);
$extra-beau-blue-100: rgb(243, 248, 252, 1);
$extra-beau-blue-200: rgb(232, 242, 249, 1);
$extra-beau-blue-300: rgb(220, 235, 245, 1);
$extra-beau-blue-400: rgb(209, 229, 242, 1);
$extra-beau-blue-500: rgb(197, 222, 239, 1);
$extra-beau-blue-600: rgb(150, 197, 227, 1);
$extra-beau-blue-700: rgb(94, 167, 210, 1);
$extra-beau-blue-800: rgb(58, 140, 189, 1);
$extra-beau-blue-900: rgb(41, 112, 160, 1);
$extra-bright-lilac-100: rgb(250, 238, 255, 1);
$extra-bright-lilac-200: rgb(245, 220, 255, 1);
$extra-bright-lilac-300: rgb(241, 203, 255, 1);
$extra-bright-lilac-400: rgb(236, 185, 255, 1);
$extra-bright-lilac-500: rgb(231, 168, 255, 1);
$extra-bright-lilac-600: rgb(217, 116, 254, 1);
$extra-bright-lilac-700: rgb(199, 64, 245, 1);
$extra-bright-lilac-800: rgb(173, 32, 217, 1);
$extra-bright-lilac-900: rgb(146, 23, 180, 1);
$extra-charcoal-100: rgb(218, 225, 233, 1);
$extra-charcoal-200: rgb(181, 195, 211, 1);
$extra-charcoal-300: rgb(145, 166, 190, 1);
$extra-charcoal-400: rgb(108, 136, 168, 1);
$extra-charcoal-500: rgb(71, 106, 146, 1);
$extra-charcoal-600: rgb(54, 79, 110, 1);
$extra-charcoal-700: rgb(48, 68, 92, 1);
$extra-charcoal-800: rgb(44, 59, 78, 1);
$extra-charcoal-900: rgb(14, 21, 29, 1);
$extra-coral-100: rgb(254, 233, 229, 1);
$extra-coral-200: rgb(252, 211, 202, 1);
$extra-coral-300: rgb(251, 188, 176, 1);
$extra-coral-400: rgb(249, 166, 149, 1);
$extra-coral-500: rgb(248, 144, 123, 1);
$extra-coral-600: rgb(238, 97, 69, 1);
$extra-coral-700: rgb(219, 69, 39, 1);
$extra-coral-800: rgb(184, 55, 29, 1);
$extra-coral-900: rgb(153, 48, 27, 1);
$extra-forest-green-crayola-100: rgb(223, 239, 236, 1);
$extra-forest-green-crayola-200: rgb(190, 223, 218, 1);
$extra-forest-green-crayola-300: rgb(158, 208, 199, 1);
$extra-forest-green-crayola-400: rgb(125, 192, 181, 1);
$extra-forest-green-crayola-500: rgb(93, 176, 162, 1);
$extra-forest-green-crayola-600: rgb(59, 145, 131, 1);
$extra-forest-green-crayola-700: rgb(45, 116, 106, 1);
$extra-forest-green-crayola-800: rgb(40, 93, 87, 1);
$extra-forest-green-crayola-900: rgb(35, 76, 71, 1);
$extra-french-sky-blue-100: rgb(228, 242, 253, 1);
$extra-french-sky-blue-200: rgb(201, 229, 252, 1);
$extra-french-sky-blue-300: rgb(174, 217, 250, 1);
$extra-french-sky-blue-400: rgb(147, 204, 249, 1);
$extra-french-sky-blue-500: rgb(120, 191, 247, 1);
$extra-french-sky-blue-600: rgb(64, 151, 241, 1);
$extra-french-sky-blue-700: rgb(42, 122, 230, 1);
$extra-french-sky-blue-800: rgb(34, 100, 211, 1);
$extra-french-sky-blue-900: rgb(34, 81, 171, 1);
$extra-green-sheen-100: rgb(226, 244, 245, 1);
$extra-green-sheen-200: rgb(196, 232, 236, 1);
$extra-green-sheen-300: rgb(167, 221, 226, 1);
$extra-green-sheen-400: rgb(137, 209, 217, 1);
$extra-green-sheen-500: rgb(108, 198, 207, 1);
$extra-green-sheen-600: rgb(80, 180, 192, 1);
$extra-green-sheen-700: rgb(52, 152, 166, 1);
$extra-green-sheen-800: rgb(46, 124, 140, 1);
$extra-green-sheen-900: rgb(43, 101, 115, 1);
$extra-heliotrope-100: rgb(242, 229, 255, 1);
$extra-heliotrope-200: rgb(229, 204, 254, 1);
$extra-heliotrope-300: rgb(217, 178, 254, 1);
$extra-heliotrope-400: rgb(204, 153, 253, 1);
$extra-heliotrope-500: rgb(191, 127, 253, 1);
$extra-heliotrope-600: rgb(169, 84, 248, 1);
$extra-heliotrope-700: rgb(148, 49, 236, 1);
$extra-heliotrope-800: rgb(127, 32, 208, 1);
$extra-heliotrope-900: rgb(108, 32, 169, 1);
$extra-magenta-crayola-100: rgb(249, 227, 245, 1);
$extra-magenta-crayola-200: rgb(244, 199, 235, 1);
$extra-magenta-crayola-300: rgb(238, 172, 226, 1);
$extra-magenta-crayola-400: rgb(233, 144, 216, 1);
$extra-magenta-crayola-500: rgb(227, 116, 206, 1);
$extra-magenta-crayola-600: rgb(218, 90, 190, 1);
$extra-magenta-crayola-700: rgb(199, 59, 161, 1);
$extra-magenta-crayola-800: rgb(172, 42, 133, 1);
$extra-magenta-crayola-900: rgb(142, 38, 110, 1);
$extra-maximum-yellow-red-100: rgb(254, 247, 238, 1);
$extra-maximum-yellow-red-200: rgb(255, 240, 221, 1);
$extra-maximum-yellow-red-300: rgb(252, 230, 200, 1);
$extra-maximum-yellow-red-400: rgb(250, 212, 161, 1);
$extra-maximum-yellow-red-500: rgb(248, 194, 122, 1);
$extra-maximum-yellow-red-600: rgb(245, 170, 69, 1);
$extra-maximum-yellow-red-700: rgb(242, 145, 15, 1);
$extra-maximum-yellow-red-800: rgb(192, 114, 10, 1);
$extra-maximum-yellow-red-900: rgb(138, 82, 7, 1);
$extra-medium-purple-100: rgb(229, 232, 254, 1);
$extra-medium-purple-200: rgb(202, 210, 253, 1);
$extra-medium-purple-300: rgb(176, 187, 253, 1);
$extra-medium-purple-400: rgb(149, 165, 252, 1);
$extra-medium-purple-500: rgb(123, 142, 251, 1);
$extra-medium-purple-600: rgb(95, 106, 245, 1);
$extra-medium-purple-700: rgb(66, 66, 233, 1);
$extra-medium-purple-800: rgb(55, 52, 206, 1);
$extra-medium-purple-900: rgb(45, 45, 166, 1);
$extra-middle-blue-green-100: rgb(231, 251, 250, 1);
$extra-middle-blue-green-200: rgb(207, 246, 245, 1);
$extra-middle-blue-green-300: rgb(183, 242, 241, 1);
$extra-middle-blue-green-400: rgb(159, 237, 236, 1);
$extra-middle-blue-green-500: rgb(135, 233, 231, 1);
$extra-middle-blue-green-600: rgb(60, 212, 211, 1);
$extra-middle-blue-green-700: rgb(32, 185, 186, 1);
$extra-middle-blue-green-800: rgb(30, 149, 156, 1);
$extra-middle-blue-green-900: rgb(30, 120, 128, 1);
$extra-naples-yellow-100: rgb(252, 248, 229, 1);
$extra-naples-yellow-200: rgb(249, 241, 204, 1);
$extra-naples-yellow-300: rgb(247, 234, 178, 1);
$extra-naples-yellow-400: rgb(244, 227, 153, 1);
$extra-naples-yellow-500: rgb(241, 220, 127, 1);
$extra-naples-yellow-600: rgb(237, 205, 92, 1);
$extra-naples-yellow-700: rgb(233, 186, 54, 1);
$extra-naples-yellow-800: rgb(225, 156, 31, 1);
$extra-naples-yellow-900: rgb(199, 121, 24, 1);
$extra-tarte-orange-100: rgb(252, 227, 231, 1);
$extra-tarte-orange-200: rgb(249, 199, 207, 1);
$extra-tarte-orange-300: rgb(246, 170, 184, 1);
$extra-tarte-orange-400: rgb(243, 142, 160, 1);
$extra-tarte-orange-500: rgb(240, 114, 136, 1);
$extra-tarte-orange-600: rgb(231, 76, 108, 1);
$extra-tarte-orange-700: rgb(211, 43, 85, 1);
$extra-tarte-orange-800: rgb(177, 31, 70, 1);
$extra-tarte-orange-900: rgb(149, 28, 66, 1);
$extra-vivid-tangerine-100: rgb(255, 238, 242, 1);
$extra-vivid-tangerine-200: rgb(255, 221, 228, 1);
$extra-vivid-tangerine-300: rgb(255, 205, 215, 1);
$extra-vivid-tangerine-400: rgb(255, 188, 201, 1);
$extra-vivid-tangerine-500: rgb(255, 171, 188, 1);
$extra-vivid-tangerine-600: rgb(254, 110, 142, 1);
$extra-vivid-tangerine-700: rgb(248, 59, 106, 1);
$extra-vivid-tangerine-800: rgb(229, 25, 86, 1);
$extra-vivid-tangerine-900: rgb(194, 14, 72, 1);
$neutral-10: rgb(252, 252, 252, 1);
$neutral-15: rgb(245, 247, 250, 1);
$neutral-25: rgb(237, 242, 247, 1);
$neutral-50: rgb(225, 234, 242, 1);
$neutral-100: rgb(213, 224, 235, 1);
$neutral-150: rgb(204, 218, 232, 1);
$neutral-200: rgb(195, 210, 224, 1);
$neutral-300: rgb(171, 185, 199, 1);
$neutral-400: rgb(127, 145, 163, 1);
$neutral-500: rgb(84, 104, 125, 1);
$neutral-600: rgb(63, 83, 102, 1);
$neutral-700: rgb(46, 62, 79, 1);
$neutral-800: rgb(32, 47, 61, 1);
$neutral-900: rgb(23, 34, 46, 1);
$orange-50: rgb(255, 237, 222, 1);
$orange-100: rgb(255, 222, 197, 1);
$orange-200: rgb(255, 209, 173, 1);
$orange-300: rgb(255, 187, 135, 1);
$orange-400: rgb(255, 160, 87, 1);
$orange-500: rgb(255, 135, 42, 1);
$orange-600: rgb(243, 110, 9, 1);
$orange-700: rgb(197, 85, 0, 1);
$orange-800: rgb(133, 57, 0, 1);
$orange-900: rgb(89, 38, 0, 1);
$primary-25: rgb(234, 243, 251, 1);
$primary-50: rgb(215, 237, 255, 1);
$primary-75: rgb(194, 228, 255, 1);
$primary-100: rgb(172, 216, 255, 1);
$primary-200: rgb(121, 193, 255, 1);
$primary-300: rgb(39, 158, 252, 1);
$primary-400: rgb(0, 129, 247, 1);
$primary-500: rgb(0, 109, 210, 1);
$primary-600: rgb(0, 97, 184, 1);
$primary-700: rgb(0, 82, 157, 1);
$primary-800: rgb(0, 68, 130, 1);
$primary-900: rgb(0, 52, 101, 1);
$purple-50: rgb(240, 221, 248, 1);
$purple-100: rgb(231, 201, 245, 1);
$purple-200: rgb(216, 171, 238, 1);
$purple-300: rgb(205, 139, 232, 1);
$purple-400: rgb(189, 105, 225, 1);
$purple-500: rgb(175, 73, 218, 1);
$purple-600: rgb(155, 42, 203, 1);
$purple-700: rgb(125, 34, 163, 1);
$purple-800: rgb(93, 24, 122, 1);
$purple-900: rgb(64, 17, 85, 1);
$success-15: rgb(238, 251, 245, 1);
$success-25: rgb(220, 247, 233, 1);
$success-50: rgb(202, 240, 221, 1);
$success-100: rgb(165, 237, 201, 1);
$success-200: rgb(134, 232, 181, 1);
$success-300: rgb(108, 226, 159, 1);
$success-400: rgb(77, 217, 131, 1);
$success-500: rgb(37, 199, 102, 1);
$success-600: rgb(32, 181, 92, 1);
$success-700: rgb(26, 158, 79, 1);
$success-800: rgb(20, 130, 66, 1);
$success-900: rgb(13, 98, 50, 1);
$text-body: rgb(23, 34, 46, 1);
$text-icon-body: rgb(63, 83, 102, 1);
$text-icon-muted: rgb(127, 145, 163, 1);
$text-muted: rgb(63, 83, 102, 1);
$text-placeholder: rgb(127, 145, 163, 1);
$text-tooltip-body: rgb(237, 242, 247, 1);
$text-tooltip-muted: rgb(213, 224, 235, 1);
$turquoise-10: rgb(243, 253, 255, 1);
$turquoise-50: rgb(195, 246, 253, 1);
$turquoise-100: rgb(167, 241, 253, 1);
$turquoise-200: rgb(134, 237, 253, 1);
$turquoise-300: rgb(103, 233, 253, 1);
$turquoise-400: rgb(63, 228, 253, 1);
$turquoise-500: rgb(21, 217, 249, 1);
$turquoise-600: rgb(5, 190, 219, 1);
$turquoise-700: rgb(4, 150, 173, 1);
$turquoise-800: rgb(2, 106, 122, 1);
$turquoise-900: rgb(2, 69, 80, 1);
$white: rgb(255, 255, 255, 1);
$yellow-25: rgb(255, 250, 235, 1);
$yellow-50: rgb(255, 245, 215, 1);
$yellow-100: rgb(253, 239, 190, 1);
$yellow-200: rgb(253, 232, 157, 1);
$yellow-300: rgb(252, 222, 115, 1);
$yellow-400: rgb(250, 212, 72, 1);
$yellow-500: rgb(249, 195, 0, 1);
$yellow-600: rgb(217, 170, 0, 1);
$yellow-700: rgb(176, 138, 0, 1);
$yellow-800: rgb(122, 96, 0, 1);
$yellow-900: rgb(81, 64, 0, 1);
