// POSITION OF FOOTER
.footer {
  position: absolute;
  left: $horizontal-padding;
  right: $horizontal-padding;
  bottom: $vertical-padding;

  .footer-brand {
    display: flex;
    flex-direction: column;
  }
}

@include media-breakpoint-down(sm) {
  .footer {
    position: static;
    padding: 3rem 1rem;
  }
}

// INSIDE LAYOUT
html.no-flexbox {
  .footer {
    padding-left: 10rem;
    padding-right: 10rem;

    .footer-brand {
      position: absolute;
      left: 0;
      bottom: 0;
      vertical-align: bottom;
    }

    .footer-lang {
      position: absolute;
      right: 0;
      bottom: 0;
    }
  }
}

html.flexbox {
  .footer {
    display: flex;
    flex-direction: row;
    justify-content: flex-start;
    align-items: center;

    .footer-nav {
      display: flex;
      margin-left: 2rem;

      > .footer-nav-link {
        flex-shrink: 0;
      }
    }

    .footer-brand {
      position: static;
      flex-grow: 0;
      flex-shrink: 0;
    }

    .footer-lang {
      margin-left: auto;
    }
  }
}

@include media-breakpoint-down(sm) {
  html.flexbox,
  html.no-flexbox {
    .footer {
      padding: 3rem 1rem;

      .footer-brand {
        position: static;
      }

      .footer-lang {
        position: static;
      }

      .footer-nav {
        display: block;
        margin: 2rem 0;
      }
    }
  }

  html.no-flexbox {
    .footer-brand {
      margin-bottom: 2rem;
    }

    .footer-lang {
      margin: 2rem 0;
    }
  }

  html.flexbox {
    .footer {
      display: flex;
      align-items: center;
      flex-wrap: wrap;

      .footer-nav {
        order: 2;
        flex-basis: 100%;
      }
    }

    .footer-lang {
      margin-left: auto;
    }
  }
}

// FOOTER NAV
.footer-nav {
  display: inline;
  margin: 0;
  padding: 0;
  list-style-type: none;

  > li {
    display: inline;
    margin-right: 2rem;

    &.footer-nav-text {
      display: inline;
      align-items: baseline;

      .btn-link {
        color: white;
        text-decoration: underline;
      }
    }

    &:last-child {
      margin-right: 0;
    }
  }

  @include media-breakpoint-down(sm) {
    > li.footer-nav-link {
      display: block;
      margin: 0 -1rem;
      border-bottom: 1px solid rgba(255, 255, 255, 0.1);

      a {
        display: block;
        text-decoration: none;
        font-weight: bold;

        padding: 1rem 1rem;
      }

      &:first-child {
        border-top: 1px solid rgba(255, 255, 255, 0.1);
      }
    }

    > li.footer-nav-text {
      display: block;
      margin-top: 1rem;
    }
  }
}

.form-language-select {
  appearance: none;
  height: 2rem;
  padding: 0 1rem 0 0.5rem;
  background-color: transparent;
  background-image: url(../images/color-chevron-up-down-white.svg);
  background-position: center right 0;
  border-radius: 4px;
  background-repeat: no-repeat;
  color: white;
  font-size: 0.875rem;
  border: 1px solid rgba(255, 255, 255, 0.1);

  &::-ms-expand {
    display: none;
  }

  option {
    color: black;
  }
}
