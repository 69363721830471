:root {
  --color-bg-body: rgb(237, 242, 247, 1);
  --color-bg-box: rgb(255, 255, 255, 1);
  --color-bg-modal-backdrop: rgb(46, 62, 79, 0.8);
  --color-bg-strong: rgb(225, 234, 242, 1);
  --color-bg-strong-hover: rgb(213, 224, 235, 1);
  --color-bg-tooltip: rgb(46, 62, 79, 1);
  --color-border-box: rgb(204, 218, 232, 1);
  --color-border-inside-box: rgb(225, 234, 242, 1);
  --color-text-body: rgb(23, 34, 46, 1);
  --color-text-icon-body: rgb(63, 83, 102, 1);
  --color-text-icon-muted: rgb(127, 145, 163, 1);
  --color-text-muted: rgb(63, 83, 102, 1);
  --color-text-placeholder: rgb(127, 145, 163, 1);
  --color-text-tooltip-body: rgb(237, 242, 247, 1);
  --color-text-tooltip-muted: rgb(213, 224, 235, 1);
}
