h1 {
  font-size: 3rem;
  font-weight: 900;
  line-height: 1em;
  margin-bottom: 16px;
}

h2 {
  font-size: 1.25rem;
  font-weight: 700;
  margin-bottom: 2rem;
}

a {
  color: inherit;
  text-decoration: underline;

  &:focus,
  &:hover,
  &:active {
    color: inherit;
  }
}
