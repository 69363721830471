// Colors
//
// Grayscale and brand colors for use across Bootstrap.

// Start with assigning color names to specific hex values.
$white: #ffffff;

// Reassign color vars to semantic color scheme
$brand-primary: $primary-500 !default;

// Body
//
// Settings for the `<body>` element.

$body-color: $white !default;

// Fonts
//
// Font, line-height, and color for body text, headings, and more.

$text-muted: rgba($white, 0.6);

// Buttons
//
// For each of Bootstrap's buttons, define text, background and border color.

$btn-primary-color: $brand-primary !default;
$btn-primary-bg: $white !default;
$btn-primary-border: $btn-primary-bg !default;

// Alerts
//
// Define alert colors, border radius, and padding.

$alert-padding-x: 1rem !default;
$alert-padding-y: 0.5rem !default;

// Form states and alerts
//
// Define colors for form feedback states and, by default, alerts.

$state-success-text: $success-700 !default;
$state-success-bg: $success-300 !default;
$state-success-border: $success-500 !default;

$state-info-text: $primary-500 !default;
$state-info-bg: $primary-300 !default;
$state-info-border: $primary-400 !default;

$state-warning-text: $yellow-700 !default;
$state-warning-bg: $yellow-300 !default;
$state-warning-border: $yellow-500 !default;
$mark-bg: $state-warning-bg !default;

$state-danger-text: $danger-600 !default;
$state-danger-bg: $danger-300 !default;
$state-danger-border: $danger-400 !default;
