:root {
  --color-black: 0 0 0;
  --color-danger-25: 255 240 240;
  --color-danger-50: 255 230 230;
  --color-danger-100: 255 207 207;
  --color-danger-200: 255 174 174;
  --color-danger-300: 254 134 134;
  --color-danger-400: 253 93 93;
  --color-danger-500: 253 50 50;
  --color-danger-600: 227 4 4;
  --color-danger-700: 179 1 1;
  --color-danger-800: 122 1 1;
  --color-danger-900: 89 0 0;
  --color-extra-acid-green-100: 240 247 225;
  --color-extra-acid-green-200: 225 238 194;
  --color-extra-acid-green-300: 211 230 164;
  --color-extra-acid-green-400: 196 221 133;
  --color-extra-acid-green-500: 181 213 103;
  --color-extra-acid-green-600: 165 201 82;
  --color-extra-acid-green-700: 135 174 52;
  --color-extra-acid-green-800: 105 138 38;
  --color-extra-acid-green-900: 80 106 33;
  --color-extra-beau-blue-100: 243 248 252;
  --color-extra-beau-blue-200: 232 242 249;
  --color-extra-beau-blue-300: 220 235 245;
  --color-extra-beau-blue-400: 209 229 242;
  --color-extra-beau-blue-500: 197 222 239;
  --color-extra-beau-blue-600: 150 197 227;
  --color-extra-beau-blue-700: 94 167 210;
  --color-extra-beau-blue-800: 58 140 189;
  --color-extra-beau-blue-900: 41 112 160;
  --color-extra-bright-lilac-100: 250 238 255;
  --color-extra-bright-lilac-200: 245 220 255;
  --color-extra-bright-lilac-300: 241 203 255;
  --color-extra-bright-lilac-400: 236 185 255;
  --color-extra-bright-lilac-500: 231 168 255;
  --color-extra-bright-lilac-600: 217 116 254;
  --color-extra-bright-lilac-700: 199 64 245;
  --color-extra-bright-lilac-800: 173 32 217;
  --color-extra-bright-lilac-900: 146 23 180;
  --color-extra-charcoal-100: 218 225 233;
  --color-extra-charcoal-200: 181 195 211;
  --color-extra-charcoal-300: 145 166 190;
  --color-extra-charcoal-400: 108 136 168;
  --color-extra-charcoal-500: 71 106 146;
  --color-extra-charcoal-600: 54 79 110;
  --color-extra-charcoal-700: 48 68 92;
  --color-extra-charcoal-800: 44 59 78;
  --color-extra-charcoal-900: 14 21 29;
  --color-extra-coral-100: 254 233 229;
  --color-extra-coral-200: 252 211 202;
  --color-extra-coral-300: 251 188 176;
  --color-extra-coral-400: 249 166 149;
  --color-extra-coral-500: 248 144 123;
  --color-extra-coral-600: 238 97 69;
  --color-extra-coral-700: 219 69 39;
  --color-extra-coral-800: 184 55 29;
  --color-extra-coral-900: 153 48 27;
  --color-extra-forest-green-crayola-100: 223 239 236;
  --color-extra-forest-green-crayola-200: 190 223 218;
  --color-extra-forest-green-crayola-300: 158 208 199;
  --color-extra-forest-green-crayola-400: 125 192 181;
  --color-extra-forest-green-crayola-500: 93 176 162;
  --color-extra-forest-green-crayola-600: 59 145 131;
  --color-extra-forest-green-crayola-700: 45 116 106;
  --color-extra-forest-green-crayola-800: 40 93 87;
  --color-extra-forest-green-crayola-900: 35 76 71;
  --color-extra-french-sky-blue-100: 228 242 253;
  --color-extra-french-sky-blue-200: 201 229 252;
  --color-extra-french-sky-blue-300: 174 217 250;
  --color-extra-french-sky-blue-400: 147 204 249;
  --color-extra-french-sky-blue-500: 120 191 247;
  --color-extra-french-sky-blue-600: 64 151 241;
  --color-extra-french-sky-blue-700: 42 122 230;
  --color-extra-french-sky-blue-800: 34 100 211;
  --color-extra-french-sky-blue-900: 34 81 171;
  --color-extra-green-sheen-100: 226 244 245;
  --color-extra-green-sheen-200: 196 232 236;
  --color-extra-green-sheen-300: 167 221 226;
  --color-extra-green-sheen-400: 137 209 217;
  --color-extra-green-sheen-500: 108 198 207;
  --color-extra-green-sheen-600: 80 180 192;
  --color-extra-green-sheen-700: 52 152 166;
  --color-extra-green-sheen-800: 46 124 140;
  --color-extra-green-sheen-900: 43 101 115;
  --color-extra-heliotrope-100: 242 229 255;
  --color-extra-heliotrope-200: 229 204 254;
  --color-extra-heliotrope-300: 217 178 254;
  --color-extra-heliotrope-400: 204 153 253;
  --color-extra-heliotrope-500: 191 127 253;
  --color-extra-heliotrope-600: 169 84 248;
  --color-extra-heliotrope-700: 148 49 236;
  --color-extra-heliotrope-800: 127 32 208;
  --color-extra-heliotrope-900: 108 32 169;
  --color-extra-magenta-crayola-100: 249 227 245;
  --color-extra-magenta-crayola-200: 244 199 235;
  --color-extra-magenta-crayola-300: 238 172 226;
  --color-extra-magenta-crayola-400: 233 144 216;
  --color-extra-magenta-crayola-500: 227 116 206;
  --color-extra-magenta-crayola-600: 218 90 190;
  --color-extra-magenta-crayola-700: 199 59 161;
  --color-extra-magenta-crayola-800: 172 42 133;
  --color-extra-magenta-crayola-900: 142 38 110;
  --color-extra-maximum-yellow-red-100: 254 247 238;
  --color-extra-maximum-yellow-red-200: 255 240 221;
  --color-extra-maximum-yellow-red-300: 252 230 200;
  --color-extra-maximum-yellow-red-400: 250 212 161;
  --color-extra-maximum-yellow-red-500: 248 194 122;
  --color-extra-maximum-yellow-red-600: 245 170 69;
  --color-extra-maximum-yellow-red-700: 242 145 15;
  --color-extra-maximum-yellow-red-800: 192 114 10;
  --color-extra-maximum-yellow-red-900: 138 82 7;
  --color-extra-medium-purple-100: 229 232 254;
  --color-extra-medium-purple-200: 202 210 253;
  --color-extra-medium-purple-300: 176 187 253;
  --color-extra-medium-purple-400: 149 165 252;
  --color-extra-medium-purple-500: 123 142 251;
  --color-extra-medium-purple-600: 95 106 245;
  --color-extra-medium-purple-700: 66 66 233;
  --color-extra-medium-purple-800: 55 52 206;
  --color-extra-medium-purple-900: 45 45 166;
  --color-extra-middle-blue-green-100: 231 251 250;
  --color-extra-middle-blue-green-200: 207 246 245;
  --color-extra-middle-blue-green-300: 183 242 241;
  --color-extra-middle-blue-green-400: 159 237 236;
  --color-extra-middle-blue-green-500: 135 233 231;
  --color-extra-middle-blue-green-600: 60 212 211;
  --color-extra-middle-blue-green-700: 32 185 186;
  --color-extra-middle-blue-green-800: 30 149 156;
  --color-extra-middle-blue-green-900: 30 120 128;
  --color-extra-naples-yellow-100: 252 248 229;
  --color-extra-naples-yellow-200: 249 241 204;
  --color-extra-naples-yellow-300: 247 234 178;
  --color-extra-naples-yellow-400: 244 227 153;
  --color-extra-naples-yellow-500: 241 220 127;
  --color-extra-naples-yellow-600: 237 205 92;
  --color-extra-naples-yellow-700: 233 186 54;
  --color-extra-naples-yellow-800: 225 156 31;
  --color-extra-naples-yellow-900: 199 121 24;
  --color-extra-tarte-orange-100: 252 227 231;
  --color-extra-tarte-orange-200: 249 199 207;
  --color-extra-tarte-orange-300: 246 170 184;
  --color-extra-tarte-orange-400: 243 142 160;
  --color-extra-tarte-orange-500: 240 114 136;
  --color-extra-tarte-orange-600: 231 76 108;
  --color-extra-tarte-orange-700: 211 43 85;
  --color-extra-tarte-orange-800: 177 31 70;
  --color-extra-tarte-orange-900: 149 28 66;
  --color-extra-vivid-tangerine-100: 255 238 242;
  --color-extra-vivid-tangerine-200: 255 221 228;
  --color-extra-vivid-tangerine-300: 255 205 215;
  --color-extra-vivid-tangerine-400: 255 188 201;
  --color-extra-vivid-tangerine-500: 255 171 188;
  --color-extra-vivid-tangerine-600: 254 110 142;
  --color-extra-vivid-tangerine-700: 248 59 106;
  --color-extra-vivid-tangerine-800: 229 25 86;
  --color-extra-vivid-tangerine-900: 194 14 72;
  --color-neutral-10: 252 252 252;
  --color-neutral-15: 245 247 250;
  --color-neutral-25: 237 242 247;
  --color-neutral-50: 225 234 242;
  --color-neutral-100: 213 224 235;
  --color-neutral-150: 204 218 232;
  --color-neutral-200: 195 210 224;
  --color-neutral-300: 171 185 199;
  --color-neutral-400: 127 145 163;
  --color-neutral-500: 84 104 125;
  --color-neutral-600: 63 83 102;
  --color-neutral-700: 46 62 79;
  --color-neutral-800: 32 47 61;
  --color-neutral-900: 23 34 46;
  --color-orange-50: 255 237 222;
  --color-orange-100: 255 222 197;
  --color-orange-200: 255 209 173;
  --color-orange-300: 255 187 135;
  --color-orange-400: 255 160 87;
  --color-orange-500: 255 135 42;
  --color-orange-600: 243 110 9;
  --color-orange-700: 197 85 0;
  --color-orange-800: 133 57 0;
  --color-orange-900: 89 38 0;
  --color-primary-25: 234 243 251;
  --color-primary-50: 215 237 255;
  --color-primary-75: 194 228 255;
  --color-primary-100: 172 216 255;
  --color-primary-200: 121 193 255;
  --color-primary-300: 39 158 252;
  --color-primary-400: 0 129 247;
  --color-primary-500: 0 109 210;
  --color-primary-600: 0 97 184;
  --color-primary-700: 0 82 157;
  --color-primary-800: 0 68 130;
  --color-primary-900: 0 52 101;
  --color-purple-50: 240 221 248;
  --color-purple-100: 231 201 245;
  --color-purple-200: 216 171 238;
  --color-purple-300: 205 139 232;
  --color-purple-400: 189 105 225;
  --color-purple-500: 175 73 218;
  --color-purple-600: 155 42 203;
  --color-purple-700: 125 34 163;
  --color-purple-800: 93 24 122;
  --color-purple-900: 64 17 85;
  --color-success-15: 238 251 245;
  --color-success-25: 220 247 233;
  --color-success-50: 202 240 221;
  --color-success-100: 165 237 201;
  --color-success-200: 134 232 181;
  --color-success-300: 108 226 159;
  --color-success-400: 77 217 131;
  --color-success-500: 37 199 102;
  --color-success-600: 32 181 92;
  --color-success-700: 26 158 79;
  --color-success-800: 20 130 66;
  --color-success-900: 13 98 50;
  --color-turquoise-10: 243 253 255;
  --color-turquoise-50: 195 246 253;
  --color-turquoise-100: 167 241 253;
  --color-turquoise-200: 134 237 253;
  --color-turquoise-300: 103 233 253;
  --color-turquoise-400: 63 228 253;
  --color-turquoise-500: 21 217 249;
  --color-turquoise-600: 5 190 219;
  --color-turquoise-700: 4 150 173;
  --color-turquoise-800: 2 106 122;
  --color-turquoise-900: 2 69 80;
  --color-white: 255 255 255;
  --color-yellow-25: 255 250 235;
  --color-yellow-50: 255 245 215;
  --color-yellow-100: 253 239 190;
  --color-yellow-200: 253 232 157;
  --color-yellow-300: 252 222 115;
  --color-yellow-400: 250 212 72;
  --color-yellow-500: 249 195 0;
  --color-yellow-600: 217 170 0;
  --color-yellow-700: 176 138 0;
  --color-yellow-800: 122 96 0;
  --color-yellow-900: 81 64 0;
}
