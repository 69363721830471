.dropdown-item {
  position: relative;
  text-decoration: none;
  padding-right: 2rem;

  &.active::after {
    content: "";
    position: absolute;
    top: 0;
    right: 0;
    width: 16px;
    height: 16px;
    margin: 6px;
    background-image: url(../images/landing/check.svg);

    html.no-svg & {
      background-image: url(../images/landing/check.png);
    }
  }
}

// In beta, popper.js is needed to position the dropup
// We dont have it on landing, so here it is back
.dropup .dropdown-menu {
  top: auto;
  bottom: 100%;
  margin-bottom: 4px;

  @include media-breakpoint-down(sm) {
    position: fixed;
    top: 0;
    bottom: 0;
    left: 0;
    right: 0;
    overflow: scroll;
  }
}
