.btn-primary {
  @include hover {
    background-color: $btn-primary-bg;
    border-color: $btn-primary-border;
  }

  &:focus,
  &.focus {
    background-color: $btn-primary-bg;
    border-color: $btn-primary-border;
  }

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-color: $btn-primary-bg;
  }
}

a.btn {
  text-decoration: none;
}
