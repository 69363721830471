@use "sass:math";

// Extends bootstrap buttons by providing new "buttons-borderless" classes.
@mixin button-borderless-variant($color, $hover-background-color, $active-background-color) {
  color: $color;
  background-image: none;
  background-color: transparent;
  border-color: transparent;

  @include hover {
    color: $color;
    background-color: $hover-background-color;
    border-color: transparent;
  }

  &:active,
  &.active,
  .open > &.dropdown-toggle {
    background-color: $active-background-color;
    border-color: transparent;

    &:hover,
    &:focus,
    &.focus {
      background-color: $active-background-color;
      border-color: transparent;
    }
  }

  .dropdown-item:hover &:hover {
    background-color: $hover-background-color;
    .icon {
      color: $color;
    }
  }

  .dropdown.show > & {
    background-color: $active-background-color;
  }
}

// ALL BUTTONS
.btn {
  transition: opacity 200ms ease-out;

  &:focus,
  &.focus {
    // reset to default chrome outline
    outline-color: rgb(77, 144, 254); // #4D90FE
    outline-offset: -2px;
    outline-style: auto;
    outline-width: 5px;
  }
}

label.btn {
  margin-bottom: 0;
}

.btn-icon {
  min-width: 4rem;
}

@mixin button-shadow {
  box-shadow: $btn-box-shadow;

  &:active {
    position: relative;
    top: 1px;
    box-shadow: $btn-active-box-shadow;
  }
}

.btn {
  &.disabled,
  &:disabled {
    cursor: not-allowed;

    &.btn-secondary {
      opacity: 0.45;
    }
  }
}

.btn-primary {
  border-color: $primary-700;
  @include button-shadow;
}

.btn-success {
  border-color: $success-700;
  @include button-shadow;
}

.btn-danger {
  border-color: $danger-700;
  @include button-shadow;
}

.btn-done {
  @include button-variant($thread-folder-done-color, $thread-folder-done-color);
  border-color: $success-700;
  @include button-shadow;
}

.btn-active-replied {
  @include button-variant($thread-folder-active-replied-color, $thread-folder-active-replied-color);
  border-color: $turquoise-700;
  @include button-shadow;
}

// BUTTON SECONDARY
// Theme color represents the color + Has a white background
@mixin button-secondary {
  @include button-variant($white, $neutral-100, $white);
  @include button-shadow;
  color: $btn-secondary-color;
}

.btn-secondary {
  @include button-secondary;
}

.table-hover tbody tr .btn-table-row {
  color: $neutral-200;
}

.table-hover tbody tr:hover .btn-table-row {
  @include button-secondary;
}

.table-sm .btn-table-row {
  display: block;
  margin: ($spacer * -0.5) 0;
}

// BTN OUTLINE
.btn-outline-background-light {
  @include button-outline-variant(white);
  mix-blend-mode: screen;

  @include hover {
    color: black;
  }

  &:active,
  &.active,
  .show > &.dropdown-toggle {
    color: black;
  }
}

.btn-rounded {
  border-radius: 100%;
}

// BTN LINK

.btn-link:focus {
  text-decoration: none;

  &:hover {
    text-decoration: $link-hover-decoration;
  }
}

.btn-link-muted,
.btn-link-muted:focus {
  color: $text-muted;
  text-decoration: underline;
  text-decoration-style: dashed;
  text-decoration-color: $neutral-300;
}

.btn-link-muted:hover {
  color: $text-muted;
}

.btn-link-tdn {
  text-decoration: none;

  &:hover {
    text-decoration: underline;
  }
}

.btn-anchor {
  padding: 0;
  border: 0;
}

.btn-group {
  .btn.btn-secondary {
    //font-size: $font-size-sm;
    border-color: $neutral-100;
    border-width: 1px;
    border-left-width: 0;
    border-right-width: 0;
    margin-bottom: 0;

    &:first-child {
      border-left-width: 1px;
    }

    &:last-child {
      border-right-width: 1px;
    }

    &.active {
      border-color: $primary-75;
      z-index: auto;
      box-shadow: $btn-box-shadow;
      font-weight: bold;
      color: $primary-500;
      background-color: $primary-50;
    }

    &.focus,
    &:focus,
    &:active {
      top: 0;
      outline: none;
      box-shadow: $btn-box-shadow;
    }
  }
}

.btn.btn-padding-lg {
  padding-left: math.div($spacer, 2);
  padding-right: math.div($spacer, 2);
}
