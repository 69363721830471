@use "../figma/generated/tokens/css/colors";
@use "../figma/generated/tokens/css/shortcut_colors";
@use "../figma/generated/tokens/css/gradients";
@use "../figma/generated/tokens/css/shadows";
@use "../figma/generated/tokens/css/spacers";

@tailwind base;
@tailwind components;
@tailwind utilities;

@layer utilities {
  .tw-icon-sm {
    @apply tw-w-3 tw-h-3 #{!important};
  }

  .tw-icon-md {
    @apply tw-w-4 tw-h-4 #{!important};
  }

  .tw-icon-lg {
    @apply tw-w-8 tw-h-8 #{!important};
  }

  .tw-bg-scale-gradient {
    background-image: linear-gradient(
      90deg,
      theme("colors.danger-400"),
      theme("colors.orange-400"),
      theme("colors.yellow-400"),
      theme("colors.extra-acid-green-500"),
      theme("colors.success-400")
    ) !important;
  }

  @mixin skeleton($color, $highlight) {
    background-image: linear-gradient(90deg, $color 35%, $highlight 50%, $color 65%);
    background-attachment: fixed;
    background-size: 400vw 100vh;
    animation: shimmer 2500ms infinite linear;

    @keyframes shimmer {
      0% {
        background-position: right;
      }
      100% {
        background-position: left;
      }
    }
  }

  .tw-skeleton {
    @include skeleton(theme("colors.neutral-200"), theme("colors.neutral-50"));
  }

  .tw-hide-scrollbars {
    -ms-overflow-style: none; /* IE and Edge */
    scrollbar-width: none; /* Firefox */

    &::-webkit-scrollbar {
      display: none; /* Chrome, Safari, Opera */
    }
  }

  .tw-bg-analysis-status-icon-pie-ai {
    background: conic-gradient(#4c7eeb, #da67d8);
  }

  @property --ring-angle {
    syntax: "<angle>";
    initial-value: 30deg;
    inherits: false;
  }

  @keyframes rotate-ring {
    0% {
      --ring-angle: 0deg;
    }
    100% {
      --ring-angle: 360deg;
    }
  }

  .tw-bg-analysis-status-icon-ring-ai {
    background: conic-gradient(
      from var(--ring-angle),
      #fcc0fc 0deg,
      #82a1f4 359deg,
      #fcc0fc 360deg
    );
    animation: rotate-ring 800ms linear infinite;
  }

  .tw-bg-analysis-status-icon-ring-primary {
    background: conic-gradient(
      from var(--ring-angle),
      #79c1ff 0deg,
      #00529d 359deg,
      #79c1ff 360deg
    );
    animation: rotate-ring 800ms linear infinite;
  }

  .tw-bg-analysis-status-icon-ring-muted {
    background: conic-gradient(
      from var(--ring-angle),
      #ccdae8 0deg,
      #54687d 359deg,
      #ccdae8 360deg
    );
    animation: rotate-ring 800ms linear infinite;
  }

  .tw-bg-analysis-status-icon-dashed-ring-ai {
    background: linear-gradient(0deg, #fedeff, #c9defd);
  }
}

::highlight(underline) {
  text-decoration: underline;
}

// we declare tw-bg-ai-gradient outside of the utilities layer to avoid tailwindcss adding !important to the background property messing with the animation
.tw-bg-ai-gradient,
.tw-bg-ai-gradient-corner,
.before\:tw-bg-ai-gradient::before,
.before\:tw-bg-ai-gradient-corner::before {
  background: linear-gradient(180deg, rgba(255, 255, 255, 0) 32.95%, #fff 84.5%),
    radial-gradient(41.59% 84.25% at 50% 8%, #fef1ff 0%, #e4f4ff 50%, rgba(228, 244, 255, 0) 100%),
    #fff;
  background-size: 1200px 600px;
  background-repeat: no-repeat;
}

.tw-bg-ai-gradient,
.before\:tw-bg-ai-gradient::before {
  background-position: top center;
  animation: slide-in 2s ease-out forwards;
}

.tw-bg-ai-gradient-corner,
.before\:tw-bg-ai-gradient-corner::before {
  background-position: top left -550px;
}

@keyframes slide-in {
  from {
    background-position: top -600px center;
  }
  to {
    background-position: top 0 center;
  }
}
