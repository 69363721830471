.l-valign-center-wrapper {
  display: table;
  width: 100%;
  height: 100%;

  > .l-valign-center {
    display: table-cell;
    vertical-align: middle;
  }
}

html.flexbox {
  .l-valign-center-wrapper {
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: stretch;
  }
}
