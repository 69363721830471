.form {
  .alert {
    width: $form-width;
    @include media-breakpoint-down(sm) {
      width: $form-width-mobile;
    }
  }

  .form-row {
    margin-bottom: 1rem;

    button {
      margin-top: 8px;
      width: $form-width;
      @include media-breakpoint-down(sm) {
        width: $form-width-mobile;
      }
    }
  }
}

.form-text {
  display: block;
  margin-top: 0.25rem;
}

.form-control {
  box-sizing: border-box;
  padding: 0.5rem 0.75rem;
  width: $form-width;
  @include media-breakpoint-down(sm) {
    width: $form-width-mobile;
  }
  font-size: 1rem;
  color: white;
  border: none;
  background-color: transparentize(white, 0.7);
  border-radius: 2px;

  &::placeholder {
    color: transparentize(white, 0.4);
  }

  // remove chrome yellow autofill
  &:-webkit-autofill,
  &:-webkit-autofill:hover,
  &:-webkit-autofill:focus,
  &:-webkit-autofill:active {
    transition: background-color 5000s ease-in-out 0s;
    -webkit-text-fill-color: white !important;
  }

  html.no-rgba & {
    background-color: #59a3db;
  }
}

.form-label {
  display: block;

  html.placeholder &.no-placeholder-only {
    @include sr-only();
  }
}

.form-control-aside {
  display: inline-block;
  margin-left: 2rem;

  @include media-breakpoint-down(sm) {
    display: block;
    margin: 0.5rem 0 0 0;
  }
}

.form-check-label {
  position: relative;
  margin: 0;
  padding-left: 20px;
  font-size: 1rem;

  input[type="checkbox"] {
    position: absolute;
    left: 0;
    top: 0.4rem;
    width: 12px;
    height: 12px;

    appearance: none;
    background-image: url(../images/landing/checkbox.svg);
    background-position: center right;

    &:checked {
      background-position: center left;
    }
  }
}

//button {
//  padding: 0.5rem 0.75rem;
//  border: none;
//  background-color: white;
//  color: $primary-500;
//  border-radius: 2px;
//  width: $form-width;
//  font-size: 1rem;
//}
