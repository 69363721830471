.btn-borderless-secondary {
  @include button-borderless-variant(
    $neutral-900,
    rgba($neutral-300, 0.1),
    rgba($neutral-300, 0.2)
  );
}

.btn-borderless-primary {
  @include button-borderless-variant(
    $primary-500,
    rgba($primary-300, 0.1),
    rgba($primary-300, 0.2)
  );
}

.btn-borderless-white {
  @include button-borderless-variant($white, rgba($white, 0.2), rgba($white, 0.2));
}

.btn-borderless-turquoise {
  @include button-borderless-variant(
    $turquoise-900,
    darken($turquoise-10, 2%),
    darken($turquoise-10, 4%)
  );
}
