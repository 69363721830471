:root {
  --spacer-px: 1px;
  --spacer-0_5: 2px;
  --spacer-1: 4px;
  --spacer-2: 8px;
  --spacer-3: 12px;
  --spacer-4: 16px;
  --spacer-5: 20px;
  --spacer-6: 24px;
  --spacer-8: 32px;
  --spacer-10: 40px;
  --spacer-12: 48px;
  --spacer-14: 56px;
  --spacer-16: 64px;
}
