.bouncing {
  display: inline-flex;

  span {
    animation: bounce 1200ms ease-out infinite;

    &:nth-child(1) {
      animation-delay: 100ms;
    }
    &:nth-child(2) {
      animation-delay: 200ms;
    }
    &:nth-child(3) {
      animation-delay: 300ms;
    }
  }
}

@keyframes bounce {
  0% {
    transform: initial;
  }

  10% {
    transform: translateY(-2px);
  }

  20% {
    transform: initial;
  }

  100% {
    transform: initial;
  }
}
